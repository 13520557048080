<template>
  <div>
    <!-- #page-header -->
    <div
      id="page-header"
      class="cover"
      data-image-src="assets/img/bg/kontakt.jpg"
    >
      <div class="container">
        <div class="-wrapper">
          <div class="_inner">
            <h1 v-translate>Dostupné služby</h1>
            <p v-translate>
              Aké služby môžete predávať v rámci partnerského programu?
            </p>
          </div>
        </div>
      </div>
    </div>
    <!-- /#page-header -->

    <!-- .blocks -->
    <div class="blocks">
      <div class="container">
        <!-- .block -->
        <section class="block -shadow">
          <header>
            <h2>
              <img src="assets/img/icons/ico_cloud.svg" v-translate />VPS a
              Cloud
            </h2>
            <p v-translate>
              Ak vám nebude stačiť bežný hosting, svojim klientom môžete
              poskytnúť niekoľkonásobne výkonnejšie riešenia v podobne
              virtuálneho servera, ktorému vyhradíte potrebný výkon, RAM aj
              priestor, prípadne dedikovať celý server.
            </p>
          </header>

          <div class="blue-box box colored">
            <div class="row">
              <div class="col-md-6">
                <h3 v-translate>Cloud VPS / Virtuálne dátové centrum</h3>
                <ul class="iconlist">
                  <li v-translate>OS Windows alebo Linux</li>
                  <li v-translate>
                    Škálovateľnosť systémových zdrojov (RAM, priestor, CPU)
                  </li>
                  <li v-translate>Virtuálne servery (Linux / Windows)</li>
                  <li v-translate>Manažment servera</li>
                  <li v-translate>Neobmedzený počet webov a domén</li>
                  <li v-translate>Cloudová infraštruktúra</li>
                  <li v-translate>Virtualizačná platforma VMware</li>
                </ul>
              </div>
              <div class="col-md-6">
                <h3 v-translate>Virtuálne dátové centrum</h3>
                <ul class="iconlist">
                  <li v-translate>
                    Pre náročné riešenia vyžadujúce vysoký výkon
                  </li>
                  <li v-translate>Virtualizačná platforma VMware</li>
                  <li v-translate>
                    Zabezpečíme starostlivosť o hardvér a konektivitu
                  </li>
                  <li v-translate>
                    Duálne napájanie a konektivita pre vyššiu spoľahlivosť a
                    dostupnosť
                  </li>
                  <li v-translate>Kvalitné Dell servery</li>
                </ul>
              </div>
            </div>
          </div>

          <ul class="links">
            <li>
              <a href="https://wy.sk/vps/" target="_blank" v-translate
                >Viac informácií o VPS</a
              >
            </li>
            <li>
              <a href="https://wy.sk/cloud/" target="_blank" v-translate
                >Viac informácií o cloud službách</a
              >
            </li>
          </ul>

          <div class="bg" data-image-src="assets/img/icons/ico_cloud.svg"></div>
        </section>
        <!-- /.block -->

        <!-- .block -->
        <section class="block -shadow">
          <header>
            <h2>
              <img
                src="assets/img/icons/ico_webhosting.svg"
                v-translate
              />Zdieľaný webhosting
            </h2>

            <p v-translate>
              Náš profesionálny webhosting sa môžete vždy spoľahnúť. Ideálny je
              pre menšie weby, blogy alebo firemné stránky. Vašim klientom
              zabezpečí rýchly a stabilný chod webových stránok.
            </p>

            <p v-translate>
              Šikovné nástroje na správu priestoru zase ušetria čas vám.
              Webhostingové balíky môžete predávať naše alebo si môžete navrhnúť
              svoje aj s vlastnými cenami
            </p>
          </header>

          <div class="orange-box box colored">
            <div class="row">
              <div class="col-md-6">
                <ul class="iconlist">
                  <li v-translate>
                    Univerzálny hosting pre webstránky na mieru
                  </li>
                  <li v-translate>Inštalácia CMS jedným klikom</li>
                  <li v-translate>Hosting pre WordPress</li>
                  <li v-translate>Multihosting až pre 30 domén</li>
                  <li v-translate>Kapacita 5GB+</li>
                  <li v-translate>Vlastný email</li>
                  <li v-translate>Zadarmo SSL certifikát Let’s Encrypt</li>
                </ul>
              </div>
              <div class="col-md-6">
                <ul class="iconlist">
                  <li v-translate>Memory limit až 512MB</li>
                  <li v-translate>
                    Neobmedzený počet emailových účtov, subdomén a databáz PHP
                    5.6 aj 7+
                  </li>
                  <li v-translate>Garantovaná dostupnosť 99,98%</li>
                  <li v-translate>
                    Aj pre hostingy postavené na Windows a ASP.NET platforme
                  </li>
                  <li v-translate>MySQL, MDB či MSSQL</li>
                </ul>
              </div>
            </div>
          </div>

          <ul class="links">
            <li>
              <a href="https://wy.sk/webhosting/" target="_blank" v-translate
                >Viac informácií o webhostingu</a
              >
            </li>
          </ul>

          <div
            class="bg"
            data-image-src="assets/img/icons/ico_webhosting.svg"
          ></div>
        </section>
        <!-- /.block -->

        <!-- .block -->
        <section class="block -shadow">
          <header>
            <h2 class="noIco">
              <span v-translate>Domény</span>
              <ul>
                <li style="color: #ea5b0c">.com</li>
                <li style="color: #ea5b0c">.cz</li>
                <li style="color: #42ace0">.eu</li>
                <li style="color: #0864a4">.info</li>
                <li style="color: #ea5b0c; font-size: 30px">.sk</li>
                <li style="color: #e6312b">.net</li>
              </ul>
            </h2>

            <p v-translate>
              Ako náš partner môžete registrovať akúkoľvek doménu pre svojho
              klienta.
            </p>

            <p v-translate>
              Samozrejmosťou je registrácia na vlastný SK-NIC identifikátor,
              veľkoobchodné ceny a žiadne skryté poplatky.
            </p>
          </header>

          <div class="red-box box colored">
            <div class="row">
              <div class="col-md-6">
                <h3 v-translate>Registrácia domén</h3>
                <ul class="iconlist">
                  <li v-translate>Viac ako 100 koncoviek</li>
                  <li v-translate>Národné, generické aj nové koncovky</li>
                  <li v-translate>
                    Registrácia na vlastný SK-NIC identifikátor
                  </li>
                  <li v-translate>Zľavy na vybrané domény</li>
                  <li v-translate>Presmerovanie domény na inú</li>
                  <li v-translate>Správa DNS záznamov</li>
                </ul>
              </div>
              <div class="col-md-6">
                <h3 v-translate>SSL certifikáty</h3>
                <ul class="iconlist">
                  <li v-translate>
                    Zadarmo Let’s Encrypt aj Let’s Encrypt WildCard pre
                    subdomény Rapid SSL
                  </li>
                  <li v-translate>WildCard Certifikáty</li>
                  <li v-translate>EV SSL certifikáty</li>
                </ul>
              </div>
            </div>
          </div>

          <ul class="links">
            <li>
              <a href="https://wy.sk/domeny/" target="_blank" v-translate
                >Viac informácií o doménach</a
              >
            </li>
          </ul>
        </section>
        <!-- /.block -->

        <!-- .block -->
        <section class="block noPadding">
          <div class="section-logo media">
            <div class="media-body media-middle">
              <span v-translate>Powered by</span>
            </div>
            <div class="media-right media-middle">
              <img src="assets/img/WEBGLOBE_logo.png" alt="WebPartner" />
            </div>
          </div>

          <div class="box">
            <div class="row">
              <div class="col-md-6">
                <ul class="iconlist">
                  <li v-translate>
                    Naše riešenia patria k najstabilnejším na slovenskom trhu
                  </li>
                  <li v-translate>Spoliehajú sa na nás malí aj veľkí hráči</li>
                  <li v-translate>
                    DELL servery s výkonnými Intel Xeon procesormi
                  </li>
                  <li v-translate>
                    Môžete predávať za naše ceny, ale aj svoje vlastné, a svoje
                    zisky si tak zvýšiť
                  </li>
                  <li v-translate>
                    Naše webhostingy patria k najstabilnejším na slovenskom trhu
                  </li>
                  <li v-translate>Pravidelné aktualizácie a správa serverov</li>
                </ul>
              </div>
              <div class="col-md-6">
                <ul class="iconlist">
                  <li v-translate>Neobmedzený traffic aj emaily</li>
                  <li v-translate>Pravidelné zálohy</li>
                  <li v-translate>
                    Patríme k najväčším registrátorom na Slovensku
                  </li>
                  <li v-translate>Registrujeme cez 50 000 domén</li>
                  <li v-translate>
                    Jednoduchá správa domény a hostingu cez administračné
                    nástroje
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <ul class="links">
            <router-link :to="{ name: 'registration' }" tag="li"
              ><a
                class="bttn -small -button margin-fix"
                target="_self"
                v-translate
                >stať sa partnerom</a
              ></router-link
            >
          </ul>
        </section>
        <!-- /.block -->
      </div>
    </div>
    <!-- /.blocks -->
  </div>
</template>

<script>
export default {
  name: "services",
  metaInfo() {
    return {
      title: this.$gettext("services_title"),
      titleTemplate: "%s | Webpartner",
      meta: [
        {
          name: "description",
          content: this.$gettext("services_meta_description"),
        },
      ],
      htmlAttrs: {
        lang: "sk-SK",
        amp: undefined,
      },
      link: [{ rel: "canonical", href: "https://webpartner.sk/sluzby" }],
    };
  },
};
</script>